@import 'app/variables.scss';

.EventsScreen {
  .settings-form {
    max-width: 750px;

    &__submit {
      margin-top: $aleph-grid-size;
    }
  }

  .Dashboard__actions {
    display: flex;
    flex-flow: row;
    align-content: center;
  }

  &__period {
    display: block;
    width: fit-content;
    margin-right: 24px;
  }
}
