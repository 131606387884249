@import 'app/variables.scss';

.EventIndex {
  &__controls {
    .QueryTags {
      margin-top: $aleph-grid-size * 1.5;
    }

    .#{$bp-ns}-input-group {
      margin-bottom: $aleph-grid-size;
    }
  }

  .index {
    width: 100%;
    border-color: gray;
    border-collapse: separate;

    .#{$bp-ns}-icon {
      margin-top: 2px;
      margin-right: 5px;
    }
  }
}
