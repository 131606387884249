@import 'app/variables.scss';

.CreateUserDialog {
  &__password {
    font-family: monospace;
    margin-top: 0.5rem;

    input {
      background-color: $aleph-dark-page-background;
      color: $aleph-text-highlight-color;
      text-align: center;
    }

    svg {
      fill: $aleph-page-background!important;
    }
  }
}