@import 'app/variables.scss';

.TutorialScreen {
  font-size: 1.3rem;
  line-height: 1.8rem;
  background: $aleph-dark-page-background;

  article, div {
    position: relative;
    padding: 2em 3em;
    margin: 0 auto;
    max-width: 900px;
    background: $aleph-page-background;
  }

  p {
    margin-bottom: 1.5rem;
  }

  ol {
    counter-reset: list;
  }
  ol > li {
    list-style: none;
    position: relative;
  }
  ol > li:before {
    counter-increment: list;
    content: counter(list, lower-latin) ") ";
    position: absolute;
    left: -1.3em;
  }
}
