@import 'app/variables.scss';

.DownloadButton {
  &__checkbox-container {
    margin: $aleph-grid-size * 2 0;

    .#{$bp-ns}-checkbox {
      margin: 0;
    }
  }
  &.disabled {
    cursor: wait;
    color: $aleph-disabled-text;

    span {
      color: $aleph-disabled-text;
    }
  }
}
