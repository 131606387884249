@import 'app/tables.scss';
@import 'app/mixins.scss';
@import 'app/variables.scss';


.EventTable {
  @extend .#{$bp-ns}-html-table;
  width: 100%;

  tr td,
  tr th {
    vertical-align: middle;
  }

  .#{$bp-ns}-icon {
    margin-top: 2px;
    margin-right: 5px;
  }
}

