@import 'app/variables.scss';

.UserIndex {
  &__controls {
    .QueryTags {
      margin-top: $aleph-grid-size * 1.5;
    }

    .#{$bp-ns}-input-group {
      margin-bottom: $aleph-grid-size;
    }
  }

  .index-item {

    .#{$bp-ns}-icon {
      margin-top: 2px;
    }

    &__details__item {
      &.external {
        color: $aleph-error-highlight-color;
      }

      &.internal {
        color: $aleph-theme-intent;
      }

      &.admin {
        color: $warning-text-color;
      }
    }
  }
}
